var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-chart-scatter-plot","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"font-weight-light truncate-title-rt fs-rt"},[_vm._v(" "+_vm._s(_vm.titulo)+" ")])]},proxy:true}])},[(_vm.accessReleased('ANALISES_SETORIZADAS_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-nova-analise","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova Análise ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"analises-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhuma análise disponível"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.nome)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.nome))])])]}},{key:"item.setorizacao_nome",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"id":"setorizacao-nome"}},[_vm._v(" "+_vm._s(item.setorizacao_nome)+" ")])]}},{key:"item.mes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.mes))+" v"+_vm._s(item.versao)+" ")]}},{key:"item.numero_setores",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"id":"numero-setores"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_setores))+" ")])]}},{key:"item.ultima_execucao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.ultima_execucao))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.accessReleased('ANALISES_SETORIZADAS_VIZUALIZAR_MAPA'))?_c('v-btn',{staticClass:"px-1",class:item.status != 'SUCESSO' ? 'cursor-block' : '',attrs:{"id":"action-visualizar","disabled":item.status != 'SUCESSO',"color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("visualizar-mapa/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye-outline ")])],1):_vm._e(),_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"id":"action-favoritar-rel","color":item.favorito ? 'primary' : 'gray',"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.favoritar(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-star ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-rel","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.canRunItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.executarItem(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" Executar ")],1)],1):_vm._e(),(
                _vm.canEditItem(item) &&
                _vm.accessReleased('ANALISES_SETORIZADAS_EDITAR')
              )?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push(("editar/" + (item.id)))}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1)],1):_vm._e(),(_vm.canDeleteItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDelete(item.id, item.nome)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v(" Deletar ")],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){(_vm.dialogCreationLog = true),
                  (_vm.dialogCreationLogData = {
                    username: item.usuario,
                    created: item.created
                  })}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar as análises")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){return _vm.deletarItem(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v("Deletar Análise #"+_vm._s(_vm.dialogDeleteData.id))]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar a análise setorizada "),_c('strong',[_vm._v(_vm._s(_vm.dialogDeleteData.titulo))]),_vm._v("? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }